<template>
  <div>
    <div class="d-flex align-center mb-4">
      <h4 class="text-h4">AT AI</h4>
      <v-spacer></v-spacer>
    </div>
    <v-row no-gutters>
      <v-col cols="12" lg="8">
        <form @submit.prevent="submit">
          <v-card elevation="4">
            <v-card-title>
              {{ form.id ? "Update rule" : "Create new rule" }}
            </v-card-title>
            <v-card-text>
              <v-row no-gutters class="align-start">
                <v-col cols="4">
                  <v-subheader>Trigger</v-subheader>
                </v-col>
                <v-col cols="8">
                  <v-autocomplete
                    v-model="form.triggerPropertyName"
                    :items="assetRuleTriggerProperties"
                    :error-messages="form.errors.triggerPropertyName"
                    :hide-details="!form.errors.triggerPropertyName"
                    class="rounded-0 mb-4"
                    background-color="#F7F7F7"
                    hide-selected
                    solo
                    flat
                    filled
                    light
                    @change="clearUnrelatedTriggerValue"
                  />
                </v-col>
              </v-row>
              <v-row no-gutters class="align-start">
                <v-col cols="4">
                  <v-subheader>Keyword</v-subheader>
                </v-col>
                <v-col cols="8">
                  <v-text-field
                    v-if="
                      form.triggerPropertyName !== 'Account name collection'
                    "
                    v-model="form.triggerValue"
                    :error-messages="form.errors.triggerValue"
                    :hide-details="!form.errors.triggerValue"
                    clearable
                    class="rounded-0 mb-4"
                    background-color="#F7F7F7"
                    hide-selected
                    solo
                    flat
                    filled
                    light
                  />
                  <v-autocomplete
                    v-else
                    v-model="form.triggerAccountNameCollectionId"
                    :items="accountNameCollections"
                    :error-messages="form.errors.triggerAccountNameCollectionId"
                    :hide-details="!form.errors.triggerAccountNameCollectionId"
                    item-text="name"
                    item-value="id"
                    class="rounded-0 mb-4"
                    background-color="#F7F7F7"
                    hide-selected
                    solo
                    flat
                    filled
                    light
                  ></v-autocomplete>
                </v-col>
              </v-row>
              <trigger-section
                v-if="form.secondTriggerLogicOperator"
                :asset-rule-trigger-properties="
                  assetRuleSecondaryTriggerProperties
                "
                :property-name="form.secondTriggerPropertyName"
                :value="form.secondTriggerValue"
                :logic-operator="form.secondTriggerLogicOperator"
                @propertyNameChanged="form.secondTriggerPropertyName = $event"
                @valueChanged="form.secondTriggerValue = $event"
              />
              <trigger-section
                v-if="form.thirdTriggerLogicOperator"
                :asset-rule-trigger-properties="
                  assetRuleSecondaryTriggerProperties
                "
                :property-name="form.thirdTriggerPropertyName"
                :value="form.thirdTriggerValue"
                :logic-operator="form.thirdTriggerLogicOperator"
                @propertyNameChanged="form.thirdTriggerPropertyName = $event"
                @valueChanged="form.thirdTriggerValue = $event"
              />
              <section-divider left v-if="!form.thirdTriggerLogicOperator">
                <div class="d-flex">
                  <v-btn
                    text
                    color="blue darken-1"
                    :loading="formLoading"
                    @click="addTrigger('AND')"
                  >
                    <p class="teal--text text--darken-4 mb-0">AND</p>
                  </v-btn>
                  <v-btn
                    text
                    color="blue darken-1"
                    :loading="formLoading"
                    @click="addTrigger('OR')"
                  >
                    <p class="teal--text text--darken-4 mb-0">OR</p>
                  </v-btn>
                  <v-btn
                    text
                    color="blue darken-1"
                    :loading="formLoading"
                    @click="addTrigger('EXCEPT')"
                  >
                    <p class="teal--text text--darken-4 mb-0">EXCEPT</p>
                  </v-btn>
                </div>
              </section-divider>
              <section-divider v-else full class="mt-2"></section-divider>
              <v-row no-gutters class="align-center">
                <v-col cols="4">
                  <v-subheader>Ignore</v-subheader>
                </v-col>
                <v-col cols="8">
                  <v-checkbox
                    v-model="form.isIgnoreRule"
                    label="If checked, assets will not be imported"
                    :error-messages="form.errors.isIgnoreRule"
                    :disabled="isIgnoreDisabled"
                  />
                </v-col>
                <v-col v-if="isIgnoreDisabled" cols="12" class="mb-4">
                  <span class="text-subtitle-2 ml-4">
                    Asset and Entry triggers cannot be combined with except on
                    ignore rules
                  </span>
                </v-col>
              </v-row>
              <v-divider></v-divider>
              <template v-if="!form.isIgnoreRule">
                <v-tabs v-model="actionsTab" show-arrows color="primary">
                  <v-tab
                    v-for="(tab, index) in attributeClasses"
                    :key="index"
                    class="body-2 text-none"
                  >
                    {{ tab.name }}
                  </v-tab>
                </v-tabs>
                <v-tabs-items v-model="actionsTab">
                  <template v-for="(tab, index) in attributeClasses">
                    <tab-co2
                      v-if="tab.id === fixedClasses.co2Id"
                      :key="`co2-${tab.id}`"
                      :current-rule-id="form.id"
                      :tab-value="index"
                      :initial-sectors="sectors"
                      :attributes="attributes"
                      :ghg-categories="ghgCategories"
                      :rerender-counter="rerenderCounter"
                      :get-dynamic-value="getDynamicValue"
                      :get-fixed-value="getFixedValue"
                      @setDynamicValue="setDynamicValue"
                      @setFixedValue="setFixedValue"
                    />
                    <tab-general
                      v-else-if="tab.id === fixedClasses.generalId"
                      :key="`general-${tab.id}`"
                      :tab-value="index"
                      :attributes="attributes"
                      :currencies="currencies"
                      :rerender-counter="rerenderCounter"
                      :get-dynamic-value="getDynamicValue"
                      :get-dynamic-second-value="getDynamicSecondValue"
                      :get-fixed-value="getFixedValue"
                      @setDynamicValue="setDynamicValue"
                      @setFixedValue="setFixedValue"
                    />
                    <tab-dynamic
                      v-else
                      :key="tab.id"
                      :tab-value="index"
                      :attribute-class="tab"
                      :attributes="attributes"
                      :currencies="currencies"
                      :get-dynamic-value="getDynamicValue"
                      :get-dynamic-second-value="getDynamicSecondValue"
                      @setDynamicValue="setDynamicValue"
                      @setDynamicSecondValue="setDynamicSecondValue"
                    />
                  </template>
                </v-tabs-items>
              </template>
              <v-divider></v-divider>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                v-if="form.id"
                text
                color="blue darken-1"
                @click="resetForm"
              >
                <v-icon class="black--text">mdi-cancel</v-icon>
                <p class="teal--text text--darken-4 mb-0">Cancel</p>
              </v-btn>
              <v-btn
                text
                color="blue darken-1"
                :disabled="!canPerformDryRun"
                :loading="dryRunLoading"
                @click="performDryRun(null, true)"
              >
                <v-icon class="black--text">mdi-list-box-outline</v-icon>
                <p class="teal--text text--darken-4 mb-0">Preview</p>
              </v-btn>
              <v-btn
                text
                color="blue darken-1"
                :disabled="!formIsFilledOut || formLoading"
                :loading="formLoading"
                @click="submit(false)"
              >
                <v-icon class="black--text">mdi-content-save-outline</v-icon>
                <p class="teal--text text--darken-4 mb-0">Save</p>
              </v-btn>
              <v-btn
                text
                color="blue darken-1"
                :disabled="!formIsFilledOut || formLoading"
                :loading="formLoading"
                @click="submit(true)"
              >
                <v-icon class="black--text">
                  mdi-content-save-alert-outline
                </v-icon>
                <p class="teal--text text--darken-4 mb-0">Save and process</p>
              </v-btn>
            </v-card-actions>
          </v-card>
        </form>
      </v-col>
      <v-col v-if="dryRunAssets !== null" cols="12" lg="8" class="mt-4">
        <v-card elevation="4">
          <v-card-title>
            Matched assets
            <span v-if="!dryRunParametersMatch" class="ml-2">
              (Trigger values changed)
            </span>
            <v-spacer></v-spacer>
            <v-btn icon @click="dryRunAssets = null">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <!-- <v-card-subtitle>
            Assets without CO2 factor: {{ matchedAssetsWithoutFactor }}
          </v-card-subtitle> -->
          <v-card-text>
            <v-data-table
              :headers="dryRunHeaders"
              :items="dryRunAssets.data"
              :server-items-length="dryRunAssets.totalCount"
              :options.sync="dryRunPagination"
              disable-sort
              @update:page="performDryRun(dryRunPagination)"
              @update:items-per-page="performDryRun(dryRunPagination)"
            >
              <template #item.factorStatus="{ item }">
                {{ getDryRunAssetStatus(item) }}
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" lg="8" class="mt-4">
        <v-card elevation="4">
          <v-card-title>Existing rules</v-card-title>
          <v-card-text>
            <v-data-table
              :headers="existingRuleHeaders"
              :items="assetRules.data"
              :server-items-length="assetRules.totalCount"
              :options.sync="pagination"
              :footer-props="tableFooterProps"
              must-sort
              @dblclick:row="(_, { item }) => editRule(item)"
              @update:page="changePage(pagination)"
              @update:items-per-page="changePage(pagination)"
              @update:sort-by="changePage(pagination)"
              @update:sort-desc="changePage(pagination)"
            >
              <template #item.trigger="{ item }">
                <span class="font-weight-bold">
                  {{ item.triggerPropertyName }}:
                </span>
                {{ formatTriggerValue(item) }}
              </template>
              <template #item.category="{ item }">
                {{ getCategoryValue(item) }}
              </template>
              <template #item.actions="{ item }">
                <v-btn small outlined color="primary" @click="editRule(item)">
                  <v-icon class="black--text">mdi-pencil</v-icon>
                  <p class="teal--text text--darken-4 mb-0">Edit</p>
                </v-btn>
                <v-btn
                  small
                  outlined
                  class="ml-2"
                  color="primary"
                  @click="deleteRule(item)"
                >
                  <v-icon class="black--text">mdi-trash-can-outline</v-icon>
                  <p class="teal--text text--darken-4 mb-0">Delete</p>
                </v-btn>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { appLayout } from "../../util/layout";
import { emissionFactorStatus } from "../../util/fixedAssetData";
import { footerProps } from "@/util/dataTable";
import qs from "qs";
import { serialize } from "object-to-formdata";
import {
  assetRuleActionProperty,
  fixedClasses,
  fixedAttributes,
} from "../../util/fixedAssetData";
import { removeTimezoneOffset } from "../../util/dateTime";

import TabDynamic from "./Form/TabDynamic.vue";
import TabGeneral from "./Form/TabGeneral.vue";
import TabCo2 from "./Form/TabCo2.vue";

import TriggerSection from "../../Components/AssetRule/TriggerSection.vue";
import SectionDivider from "../../Components/SectionDivider.vue";

export default {
  layout: appLayout({ title: "AT AI" }),
  components: {
    TabDynamic,
    TabGeneral,
    TabCo2,
    TriggerSection,
    SectionDivider,
  },
  props: {
    assetRules: Object,
    sectors: Array,
    attributeClasses: Array,
    attributes: Array,
    currencies: Array,
    assetRuleTriggerProperties: Array,
    assetRuleEntryTriggerProperties: Array,
    accountNameCollections: Array,
    ghgCategories: Array,
  },
  data() {
    const searchParams = qs.parse(window.location.search.substring(1));
    const premadeActions = [
      {
        attributeId: fixedAttributes.co2EmissionSourceId,
        guidValue: "6d9ea628-4dcf-4d77-ad8b-7e8ea4d72dde",
      },
      {
        propertyName: assetRuleActionProperty.ghgCategory,
        stringValue: "a588c70c-a620-4d67-b65e-5ef7b11a8f87",
      },
      {
        propertyName: assetRuleActionProperty.sector,
        guidValue: "08db14dc-dfe9-4494-8d06-c157bf172c8f",
      },
    ];

    return {
      emissionCategories: [],
      emissionRegions: [],
      emissionFactors: [],
      actionsTab: 2,
      emissionCategoriesLoading: false,
      emissionRegionsLoading: false,
      emissionFactorsLoading: false,
      pagination: {
        page: this.assetRules?.currentPage ?? 1,
        itemsPerPage: 50,
        sortBy: [searchParams.sortBy ?? "keyword"],
        sortDesc: [searchParams.sortDesc === "true"],
      },
      tableFooterProps: footerProps,
      emissionSectorId: null,
      emissionCategoryId: null,
      emissionRegionId: null,
      premadeActions,
      form: this.$inertia.form({
        id: null,
        triggerPropertyName: this.assetRuleTriggerProperties[0] ?? null,
        triggerValue: null,
        triggerAccountNameCollectionId: null,
        secondTriggerPropertyName: null,
        secondTriggerValue: null,
        secondTriggerLogicOperator: null,
        thirdTriggerPropertyName: null,
        thirdTriggerValue: null,
        thirdTriggerLogicOperator: null,
        actions: premadeActions,
        isIgnoreRule: false,
      }),
      existingRuleHeaders: [
        { text: "ID", value: "referenceKey" },
        { text: "Trigger", value: "trigger" },
        { text: "Category", value: "category" },
        { text: "Actions", value: "actions", sortable: false, width: 240 },
      ],
      dryRunHeaders: [
        { text: "ID", value: "referenceKey" },
        { text: "Name", value: "name" },
        { text: "Organization", value: "organizationName" },
        { text: "Emission factor status", value: "factorStatus" },
      ],
      currentDryRunParameters: {
        triggerPropertyName: null,
        triggerValue: null,
        secondTriggerPropertyName: null,
        secondTriggerValue: null,
        secondTriggerLogicOperator: null,
        thirdTriggerPropertyName: null,
        thirdTriggerValue: null,
        thirdTriggerLogicOperator: null,
      },
      dryRunAssets: null,
      dryRunPagination: {
        page: 1,
        itemsPerPage: 10,
        sortBy: [],
        sortDesc: [],
      },
      formLoading: false,
      dryRunLoading: false,
      rerenderCounter: 0,
      fixedClasses,
    };
  },
  methods: {
    checkArrayProperties(array) {
      const requiredCo2Attributes = [
        { propertyName: assetRuleActionProperty.sector },
        { propertyName: assetRuleActionProperty.ghgCategory },
        { attributeId: fixedAttributes.co2EmissionSourceId },
      ];

      function containsPropertyWithValue(obj, property, value) {
        return (
          Object.prototype.hasOwnProperty.call(obj, property) &&
          obj[property] === value
        );
      }

      for (const attributes of requiredCo2Attributes) {
        let found = false;
        for (const obj of array) {
          const [key, value] = Object.entries(attributes)[0];
          if (containsPropertyWithValue(obj, key, value)) {
            found = true;
            break;
          }
        }
        if (!found) {
          return false;
        }
      }
      return true;
    },
    submit(updateAssets = false) {
      const confirmText =
        "Are you sure you want to continue? Matched assets without CO2 factors will be updated.";
      if (updateAssets && !confirm(confirmText)) return;

      this.formLoading = true;

      const transformedForm = this.form.transform((data) =>
        serialize(
          {
            ...data,
            isIgnoreRule: data.isIgnoreRule.toString(),
            actions: data.actions.map((action) => ({
              ...action,
              attribute: undefined,
              files: undefined,
              fileIds: action.files?.map((file) => file.id),
            })),
            applyToExisting: updateAssets,
          },
          { indices: true, dotsForObjectNotation: true }
        )
      );

      if (this.form.id) {
        transformedForm.put(this.route("asset-rules.update", this.form.id), {
          onSuccess: () => {
            this.resetForm();
          },
          onFinish: () => {
            this.formLoading = false;
          },
        });
        return;
      }

      transformedForm.post(this.route("asset-rules.store"), {
        onSuccess: () => {
          this.resetForm();
        },
        onFinish: () => {
          this.formLoading = false;
        },
      });
    },
    performDryRun(options, reset = false) {
      if (reset) {
        options = { page: 1, itemsPerPage: 10, sortBy: [], sortDesc: [] };
        this.dryRunPagination = options;
      }

      this.dryRunLoading = true;

      const body = {
        triggerPropertyName: this.form.triggerPropertyName,
        triggerValue: this.form.triggerValue,
        triggerAccountNameCollectionId:
          this.form.triggerAccountNameCollectionId,

        secondTriggerPropertyName: this.form.secondTriggerPropertyName,
        secondTriggerValue: this.form.secondTriggerValue,
        secondTriggerLogicOperator: this.form.secondTriggerLogicOperator,

        thirdTriggerPropertyName: this.form.thirdTriggerPropertyName,
        thirdTriggerValue: this.form.thirdTriggerValue,
        thirdTriggerLogicOperator: this.form.thirdTriggerLogicOperator,

        page: options.page,
        pageSize: options.itemsPerPage,
      };

      const formData = serialize(body);

      fetch(this.route("api.asset-rules.dry-run"), {
        method: "POST",
        body: formData,
      })
        .then((res) => res.json())
        .then((data) => {
          this.currentDryRunParameters.triggerPropertyName =
            body.triggerPropertyName;
          this.currentDryRunParameters.triggerValue = body.triggerValue;
          this.currentDryRunParameters.triggerAccountNameCollectionId =
            body.triggerAccountNameCollectionId;

          this.currentDryRunParameters.secondTriggerPropertyName =
            body.secondTriggerPropertyName;
          this.currentDryRunParameters.secondTriggerValue =
            body.secondTriggerValue;
          this.currentDryRunParameters.secondTriggerLogicOperator =
            body.secondTriggerLogicOperator;

          this.currentDryRunParameters.thirdTriggerPropertyName =
            body.thirdTriggerPropertyName;
          this.currentDryRunParameters.thirdTriggerValue =
            body.thirdTriggerValue;
          this.currentDryRunParameters.thirdTriggerLogicOperator =
            body.thirdTriggerLogicOperator;

          this.dryRunAssets = data;
        })
        .finally(() => (this.dryRunLoading = false));
    },
    resetForm() {
      this.form = this.$inertia.form({
        id: null,
        triggerPropertyName: this.assetRuleTriggerProperties[0] ?? null,
        triggerValue: null,
        triggerAccountNameCollectionId: null,
        secondTriggerPropertyName: null,
        secondTriggerValue: null,
        secondTriggerLogicOperator: null,
        thirdTriggerPropertyName: null,
        thirdTriggerValue: null,
        thirdTriggerLogicOperator: null,
        actions: this.premadeActions,
        isIgnoreRule: false,
      });
      this.emissionSectorId = null;
      this.emissionCategoryId = null;
      this.emissionRegionId = null;
      this.dryRunAssets = null;
    },
    changePage(options) {
      const query = {
        sortBy: options.sortBy[0],
        sortDesc: options.sortDesc[0],
        page: options.page,
        pageSize: options.itemsPerPage,
      };

      this.$inertia.get(
        this.route("asset-rules.index", query),
        {},
        { preserveState: true }
      );
    },
    scrollToTop() {
      window.scrollTo(0, 0);
    },
    editRule(item) {
      this.scrollToTop();
      const confirmText =
        "Are you sure you want to continue? Any changes in the form will be discarded.";

      if (this.form.isDirty && !confirm(confirmText)) return;

      this.form = this.$inertia.form({
        id: item.id,

        triggerPropertyName: item.triggerPropertyName,
        triggerValue: item.triggerValue,
        triggerAccountNameCollectionId: item.triggerAccountNameCollectionId,

        secondTriggerPropertyName: item.secondTriggerPropertyName,
        secondTriggerValue: item.secondTriggerValue,
        secondTriggerLogicOperator: item.secondTriggerLogicOperator,

        thirdTriggerPropertyName: item.thirdTriggerPropertyName,
        thirdTriggerValue: item.thirdTriggerValue,
        thirdTriggerLogicOperator: item.thirdTriggerLogicOperator,

        actions: item.actions ?? [],
        isIgnoreRule: item.isIgnoreRule,
      });
    },
    deleteRule(item) {
      if (!confirm("Are you sure you want to delete the selected rule?"))
        return;

      this.$inertia.delete(this.route("asset-rules.destroy", item.id), {
        preserveState: true,
      });

      // if editing for with item id clear form
      if (this.form.id === item.id) {
        this.resetForm();
      }
    },
    getDryRunAssetStatus(asset) {
      if (!asset) return "Invalid asset";

      const isDefining =
        asset.emissionFactorStatus === emissionFactorStatus.defining;
      const isDefined =
        asset.emissionFactorStatus === emissionFactorStatus.defined;
      const isApproved =
        asset.emissionFactorStatus === emissionFactorStatus.approved;

      if (isDefining) return "Scheduled for categorization";

      if (!asset.hasCo2Factor) return "Not defined";

      if (isDefined && !asset.aiDefined) return "Defined by rule";
      else if (isApproved && !asset.aiDefined)
        return "Defined/Approved by user";

      if (asset.aiDefined && isApproved) return "Defined by AI (approved)";
      else if (asset.aiDefined) return "Defined by AI";
    },
    getCategoryValue(assetRule) {
      const categoryAction = assetRule.actions.find(
        (x) => x.propertyName === assetRuleActionProperty.category
      );

      return categoryAction?.displayText ?? "";
    },
    getFixedValue(propertyName) {
      const action = this.form.actions.find(
        (x) => x.propertyName === propertyName
      );

      if (!action) return null;

      switch (action.propertyName) {
        case assetRuleActionProperty.category:
        case assetRuleActionProperty.emissionFactorName:
        case assetRuleActionProperty.region:
        case assetRuleActionProperty.sector:
        case assetRuleActionProperty.ghgCategory:
          return action.guidValue ?? null;
        default:
          return null;
      }
    },
    setFixedValue(propertyName, value) {
      if (!value) {
        this.form.actions = this.form.actions.filter(
          (x) => x.propertyName !== propertyName
        );
        return;
      }

      let action = this.form.actions.find(
        (x) => x.propertyName === propertyName
      );

      if (!action) {
        action = { propertyName };

        this.form.actions.push(action);
      }

      switch (action.propertyName) {
        case assetRuleActionProperty.category:
        case assetRuleActionProperty.emissionFactorName:
        case assetRuleActionProperty.region:
        case assetRuleActionProperty.sector:
        case assetRuleActionProperty.ghgCategory:
          action.guidValue = value;
          break;
      }

      this.rerenderCounter++;
    },
    getDynamicValue(attributeId) {
      const action = this.form.actions.find(
        (x) => x.attributeId === attributeId
      );

      const attribute = this.attributes.find((x) => x.id === attributeId);

      if (!action || !attribute) return null;

      switch (attribute.format) {
        case "Text":
        case "TextArea":
          return action.stringValue ?? null;
        case "Number":
        case "Percentage":
        case "Currency":
          return action.decimalValue ?? null;
        case "Date":
        case "DateTime":
          return action.dateTimeValue ?? null;
        case "Boolean":
          return action.boolValue ?? null;
        case "File":
          return action.newFiles ?? null;
        case "Select":
          return action.guidValue ?? null;
        default:
          return null;
      }
    },
    getDynamicSecondValue(attributeId) {
      const action = this.form.actions.find(
        (x) => x.attributeId === attributeId
      );

      const attribute = this.attributes.find((x) => x.id === attributeId);

      if (!action || !attribute) return null;

      switch (attribute.format) {
        case "Currency":
          return action.stringValue ?? null;
        case "File":
          return action.files ?? null;
        default:
          return null;
      }
    },
    setDynamicValue(attributeId, value) {
      if (!value) {
        this.form.actions = this.form.actions.filter(
          (x) => x.attributeId !== attributeId
        );
        return;
      }

      const attribute = this.attributes.find((x) => x.id === attributeId);

      if (!attribute) return;

      let action = this.form.actions.find((x) => x.attributeId === attributeId);

      if (!action) {
        action = { attributeId };

        this.form.actions.push(action);
      }

      switch (attribute.format) {
        case "Text":
        case "TextArea":
          action.stringValue = value;
          break;
        case "Number":
        case "Percentage":
          action.decimalValue = value;
          break;
        case "Currency":
          action.decimalValue = value;

          if (!action.stringValue && this.defaultCurrencyCode)
            action.stringValue = this.defaultCurrencyCode;
          break;
        case "Date":
        case "DateTime":
          action.dateTimeValue = value ? removeTimezoneOffset(value) : value;
          break;
        case "Boolean":
          action.boolValue = value;
          break;
        case "File":
          action.newFiles = value;
          break;
        case "Select":
          action.guidValue = value;
          break;
      }

      this.rerenderCounter++;
    },
    setDynamicSecondValue(attributeId, value) {
      if (!value && !this.getDynamicValue(attributeId)) {
        this.form.actions = this.form.actions.filter(
          (x) => x.attributeId !== attributeId
        );
        return;
      }

      const attribute = this.attributes.find((x) => x.id === attributeId);

      if (!attribute) return;

      let action = this.form.actions.find((x) => x.attributeId === attributeId);

      if (!action) {
        action = { attributeId };

        this.form.actions.push(action);
      }

      switch (attribute.format) {
        case "Currency":
          action.stringValue = value;
          break;
      }

      this.rerenderCounter++;
    },
    addTrigger(logicOperator) {
      if (!this.form.secondTriggerLogicOperator) {
        this.form.secondTriggerLogicOperator = logicOperator;
        return;
      }

      if (!this.form.thirdTriggerLogicOperator) {
        this.form.thirdTriggerLogicOperator = logicOperator;
        return;
      }
    },
    formatTriggerValue(assetRule) {
      return (
        assetRule.triggerAccountNameCollection?.name ?? assetRule.triggerValue
      );
    },
    clearUnrelatedTriggerValue(propertyName) {
      if (propertyName === "Account name collection") {
        this.form.triggerValue = null;
        return;
      }

      this.form.triggerAccountNameCollectionId = null;
    },
  },
  computed: {
    assetRuleSecondaryTriggerProperties() {
      let assetRuleSecondaryTriggerProperties =
        this.assetRuleTriggerProperties.filter(
          (element) => element !== "Account name collection"
        );

      return assetRuleSecondaryTriggerProperties;
    },
    formIsFilledOut() {
      const hasTriggerProperty = !!this.form.triggerPropertyName;
      const hasTriggerValue =
        (this.form.triggerValue ?? "").trim() !== "" ||
        (this.form.triggerAccountNameCollectionId ?? "").trim() !== "";
      const hasCo2Data = this.form.isIgnoreRule
        ? true
        : this.checkArrayProperties(this.form.actions);
      const hasEffect = this.form.isIgnoreRule || this.form.actions.length > 0;

      return hasTriggerProperty && hasTriggerValue && hasEffect && hasCo2Data;
    },
    matchedAssetsWithoutFactor() {
      if (!this.dryRunAssets) return null;

      return this.dryRunAssets.data.filter(
        (x) =>
          !x.hasCo2Factor &&
          x.emissionFactorStatus !== emissionFactorStatus.defining
      ).length;
    },
    canPerformDryRun() {
      return (
        !!this.form.triggerPropertyName &&
        ((!!this.form.triggerValue && !!this.form.triggerValue.trim()) ||
          !!this.form.triggerAccountNameCollectionId) &&
        !this.dryRunLoading
      );
    },
    dryRunParametersMatch() {
      return (
        this.form.triggerPropertyName ===
          this.currentDryRunParameters.triggerPropertyName &&
        this.form.triggerValue === this.currentDryRunParameters.triggerValue &&
        this.form.triggerAccountNameCollectionId ===
          this.currentDryRunParameters.triggerAccountNameCollectionId &&
        this.form.secondTriggerPropertyName ===
          this.currentDryRunParameters.secondTriggerPropertyName &&
        this.form.secondTriggerValue ===
          this.currentDryRunParameters.secondTriggerValue &&
        this.form.secondTriggerLogicOperator ===
          this.currentDryRunParameters.secondTriggerLogicOperator &&
        this.form.thirdTriggerPropertyName ===
          this.currentDryRunParameters.thirdTriggerPropertyName &&
        this.form.thirdTriggerValue ===
          this.currentDryRunParameters.thirdTriggerValue &&
        this.form.thirdTriggerLogicOperator ===
          this.currentDryRunParameters.thirdTriggerLogicOperator
      );
    },
    triggerProperties() {
      return [
        this.form.triggerPropertyName,
        this.form.secondTriggerPropertyName,
        this.form.thirdTriggerPropertyName,
      ];
    },
    logicOperators() {
      return [
        this.form.secondTriggerLogicOperator,
        this.form.thirdTriggerLogicOperator,
      ];
    },
    isIgnoreDisabled() {
      const triggerProperties = this.triggerProperties.filter((x) => !!x);
      const logicOperators = this.logicOperators.filter((x) => !!x);

      const hasEntryProperty = triggerProperties.some((x) =>
        this.assetRuleEntryTriggerProperties.includes(x)
      );

      const hasNonEntryProperty = triggerProperties.some(
        (x) => !this.assetRuleEntryTriggerProperties.includes(x)
      );

      const hasExceptOperator = logicOperators.some((x) => x === "EXCEPT");

      // Except logic cannot be enforced with asset properties due to how entry property ignore rules are applied
      return hasExceptOperator && hasEntryProperty && hasNonEntryProperty;
    },
  },
  watch: {
    "form.isIgnoreRule": function (value) {
      if (!value) return;

      this.form.actions = this.premadeActions;
    },
    isIgnoreDisabled(value) {
      if (!value) return;

      this.form.isIgnoreRule = false;
    },
  },
};
</script>
