<template>
  <div>
    <div class="d-flex align-center mb-4">
      <h4 class="text-h4">Factor explorer</h4>
      <v-spacer></v-spacer>
    </div>
    <v-row justify="start" class="d-flex align-center">
      <v-col md="4">
        <v-text-field
          :value="filters.search"
          append-icon="mdi-magnify"
          label="Search"
          clearable
          single-line
          dense
          solo
          hide-details
          @change="filters.search = $event"
        ></v-text-field>
      </v-col>
      <v-col md="2">
        <v-autocomplete
          v-model="filters.sectorId"
          :items="emissionSectors"
          item-text="name"
          item-value="id"
          label="Sector"
          placeholder="Filter by sector..."
          clearable
          solo
          dense
          hide-details
          @change="handleSectorFilterChanged"
        ></v-autocomplete>
      </v-col>
      <v-col md="2">
        <v-autocomplete
          v-model="filters.categoryId"
          :items="availableEmissionCategories"
          item-text="name"
          item-value="id"
          label="Category"
          placeholder="Filter by category..."
          clearable
          solo
          dense
          hide-details
        ></v-autocomplete>
      </v-col>
      <v-col md="2">
        <v-autocomplete
          v-model="filters.disable"
          :items="frontendOptions"
          item-text="text"
          item-value="value"
          label="Frontend status"
          placeholder="Filter by frontend status..."
          clearable
          solo
          dense
          hide-details
        ></v-autocomplete>
      </v-col>
      <v-col md="2">
        <v-autocomplete
          v-model="filters.aiDisable"
          :items="aiOptions"
          item-text="text"
          item-value="value"
          label="AI status"
          placeholder="Filter by AI status..."
          clearable
          solo
          dense
          hide-details
        ></v-autocomplete>
      </v-col>
      <v-col md="2">
        <v-autocomplete
          v-model="filters.regionId"
          :items="emissionRegions"
          item-text="name"
          item-value="id"
          label="Region"
          placeholder="Filter by region..."
          clearable
          solo
          dense
          hide-details
        ></v-autocomplete>
      </v-col>
      <v-col md="2">
        <v-autocomplete
          v-model="filters.source"
          :items="sources"
          label="Source"
          placeholder="Filter by source..."
          clearable
          solo
          dense
          hide-details
        ></v-autocomplete>
      </v-col>
      <v-col md="2">
        <v-autocomplete
          v-model="filters.yearReleased"
          :items="years"
          label="Year"
          placeholder="Filter by year..."
          clearable
          solo
          dense
          hide-details
        ></v-autocomplete>
      </v-col>
      <v-col md="2">
        <v-autocomplete
          v-model="filters.activityUnit"
          :items="activityUnits"
          label="Unit type"
          placeholder="Filter by unit type..."
          clearable
          solo
          dense
          hide-details
        ></v-autocomplete>
      </v-col>
      <v-col md="2">
        <v-autocomplete
          v-model="filters.update"
          :items="updatedOptions"
          item-text="text"
          item-value="value"
          label="Updated status"
          placeholder="Filter by updated status..."
          clearable
          solo
          dense
          hide-details
        ></v-autocomplete>
      </v-col>
    </v-row>
    <v-row justify="start" class="d-flex align-center">
      <v-col md="2">
        <v-btn
          color="primary"
          class="fill-width"
          @click="bulkToggleDisabled(false, false)"
          >Bulk Enable Frontend</v-btn
        >
      </v-col>
      <v-col md="2">
        <v-btn
          color="primary"
          class="fill-width"
          @click="bulkToggleDisabled(true, false)"
          >Bulk Disable Frontend</v-btn
        >
      </v-col>
      <v-col md="2">
        <v-btn
          color="primary"
          class="fill-width"
          @click="bulkToggleDisabled(false, true)"
          >Bulk Enable AI</v-btn
        >
      </v-col>
      <v-col md="2">
        <v-btn
          color="primary"
          class="fill-width"
          @click="bulkToggleDisabled(true, true)"
          >Bulk Disable AI</v-btn
        >
      </v-col>
    </v-row>
    <v-data-table
      :headers="headers"
      :items="emissionFactors.data"
      :server-items-length="emissionFactors.totalCount"
      :options.sync="pagination"
      :footer-props="tableFooterProps"
      must-sort
      class="factor-table"
      @update:page="changePage(pagination)"
      @update:items-per-page="changePage(pagination)"
      @update:sort-by="changePage(pagination)"
      @update:sort-desc="changePage(pagination)"
    >
      <template #item.activityId="{ value }">
        <v-tooltip top>
          <template #activator="{ on, attrs }">
            <span
              v-on="on"
              v-bind="attrs"
              class="d-inline-block text-truncate fill-width"
            >
              {{ value }}
            </span>
          </template>
          <span>{{ value }}</span>
        </v-tooltip>
      </template>
      <template #item.name="{ item }">
        <div class="d-flex flex-column">
          <v-tooltip top :open-delay="250">
            <template #activator="{ on, attrs }">
              <span
                v-on="on"
                v-bind="attrs"
                class="d-inline-block text-truncate fill-width"
              >
                {{ item.name }}
              </span>
            </template>
            <div>{{ item.name }}</div>
          </v-tooltip>
          <v-tooltip top :open-delay="250">
            <template #activator="{ on, attrs }">
              <span
                v-on="on"
                v-bind="attrs"
                class="d-inline-block text-truncate fill-width text--secondary"
              >
                {{ item.activityId }}
              </span>
            </template>
            <div>{{ item.activityId }}</div>
          </v-tooltip>
        </div>
      </template>
      <template #item.source="{ item }">
        <div class="d-flex flex-column">
          <span v-if="item.source">{{ item.source }}</span>
          <span v-else> &nbsp; </span>
          <span class="relative">
            &nbsp;
            <span class="absolute text--secondary text-nowrap" style="left: 0">
              UUID: {{ item.id }}
            </span>
          </span>
        </div>
      </template>
      <template #item.yearReleased="{ value }">
        <div class="d-flex flex-column">
          <span>{{ value }}</span>
          <span>&nbsp;</span>
        </div>
      </template>
      <template v-slot:[`item.region.name`]="{ value }">
        <div class="d-flex flex-column">
          <span>{{ value }}</span>
          <span>&nbsp;</span>
        </div>
      </template>
      <template #item.activityUnit="{ value, item }">
        <div class="d-flex flex-column">
          <span>{{ value ?? "Unknown" }}</span>
          <span class="relative">
            &nbsp;
            <v-tooltip top :open-delay="250">
              <template #activator="{ on, attrs }">
                <span
                  v-on="on"
                  v-bind="attrs"
                  class="absolute text-truncate text-nowrap text--secondary"
                  style="
                    left: 0;
                    max-width: 300px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                  "
                >
                  LCA:
                  {{
                    item.lcaActivity
                      ? item.lcaActivity.slice(0, 15) +
                        (item.lcaActivity.length > 15 ? "..." : "")
                      : "Unknown"
                  }}
                </span>
              </template>
              <div>{{ item.lcaActivity }}</div>
            </v-tooltip>
          </span>
        </div>
      </template>

      <template #item.kgCo2eAr6="{ item }">
        <div class="d-flex flex-column">
          <span>{{ formatEmissions(item) }}</span>
          <span class="relative">
            &nbsp;
            <v-tooltip top :open-delay="250">
              <template #activator="{ on, attrs }">
                <span
                  v-on="on"
                  v-bind="attrs"
                  class="absolute text-truncate text-nowrap text--secondary"
                  style="
                    left: 0;
                    max-width: 300px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                  "
                >
                  {{ formatDate(item.updatedAt) }}
                </span>
              </template>
              <div>{{ formatDate(item.updatedAt) }}</div>
            </v-tooltip>
          </span>
        </div>
      </template>

      <template #item.aiDisabledAt="{ item, value }">
        <v-checkbox
          :input-value="value === null"
          :disabled="toggleLoading"
          dense
          hide-details
          class="table-checkbox mt-0 pt-0"
          @change="toggleDisabled(item.id, !$event, true)"
        ></v-checkbox>
      </template>
      <template #item.disabledAt="{ item, value }">
        <v-checkbox
          :input-value="value === null"
          :disabled="toggleLoading"
          dense
          hide-details
          class="table-checkbox mt-0 pt-0"
          @change="toggleDisabled(item.id, !$event, false)"
        ></v-checkbox>
      </template>
    </v-data-table>
  </div>
</template>
<script>
import qs from "qs";
import pickBy from "lodash/pickBy";
import debounce from "lodash/debounce";
import { serialize } from "object-to-formdata";

import { appLayout } from "../../util/layout";
import { normalizeISOString, toLocaleUTCDateString } from "../../util/dateTime";
import { footerProps } from "@/util/dataTable";

export default {
  layout: appLayout({ title: "Emission factor overview" }),
  props: {
    emissionFactors: Object,
    emissionSectors: Array,
    emissionCategories: Array,
    emissionRegions: Array,
    activityUnits: Array,
    years: Array,
    sources: Array,
  },
  data() {
    const searchParams = qs.parse(window.location.search.substring(1));

    let yearReleased = searchParams?.filters?.yearReleased ?? null;

    return {
      pagination: {
        page: this.emissionFactors?.currentPage ?? 1,
        itemsPerPage: 50,
        sortBy: [searchParams.sortBy ?? "id"],
        sortDesc: [searchParams.sortDesc === "true"],
      },
      tableFooterProps: footerProps,

      filters: {
        sectorId: searchParams?.filters?.sectorId ?? null,
        categoryId: searchParams?.filters?.categoryId ?? null,
        regionId: searchParams?.filters?.regionId ?? null,
        source: searchParams?.filters?.source ?? null,
        yearReleased: yearReleased !== null ? parseInt(yearReleased) : null,
        activityUnit: searchParams?.filters?.activityUnit ?? null,
        search: searchParams?.filters?.search ?? null,
        disable: searchParams?.filters?.disable ?? null,
        aiDisable: searchParams?.filters?.aiDisable ?? null,
        update: searchParams?.filters?.update ?? null,
      },

      headers: [
        { text: "FE", value: "disabledAt", align: "center", width: 66 },
        { text: "AI", value: "aiDisabledAt", align: "center", width: 66 },
        {
          text: "Activity name",
          value: "name",
          cellClass: "max-w-450 expand",
          width: 450,
        },
        { text: "Source", value: "source", width: 150 },
        { text: "Year", value: "yearReleased", width: 90 },
        { text: "Region", value: "region.name", width: 120 },
        { text: "Unit type", value: "activityUnit", width: 150 },
        { text: "Emission factor", value: "kgCo2eAr6", width: 175 },
      ],

      // String based boolean values are easier to handle because they are used in URL string
      frontendOptions: [
        { text: "All frontend", value: null },
        { text: "Disabled in frontend", value: "true" },
        { text: "Enabled in frontend", value: "false" },
      ],

      aiOptions: [
        { text: "All AI", value: null },
        { text: "AI disabled", value: "true" },
        { text: "AI enabled", value: "false" },
      ],

      updatedOptions: [
        { text: "All updated", value: null },
        { text: "Updated last month", value: "true" },
        { text: "Updated last year", value: "false" },
      ],

      toggleLoading: false,
    };
  },
  computed: {
    availableEmissionCategories() {
      if (!this.filters.sectorId) return this.emissionCategories;

      return this.emissionCategories.filter(
        (x) => x.sectorId === this.filters.sectorId
      );
    },
  },
  methods: {
    formatEmissions(item) {
      let factor = item.kgCo2eAr6;

      if (!factor) factor = item.kgCo2eAr5;
      if (!factor) factor = item.kgCo2eAr4;
      if (!factor) factor = 0;

      return `${factor} kgCO2e`;
    },
    formatDate(date) {
      const isoDate = normalizeISOString(date);

      return toLocaleUTCDateString(isoDate);
    },
    changePage(options) {
      const query = {
        sortBy: options.sortBy[0],
        sortDesc: options.sortDesc[0],
        filters: pickBy(this.filters),
        page: options.page,
        pageSize: options.itemsPerPage,
      };

      if (this.filters.update !== null) {
        query.sortBy = "updatedAt";
        query.sortDesc = true;
      }

      this.$inertia.get(
        this.route("emission-factors.index", query),
        {},
        { preserveState: true, preserveScroll: true }
      );
    },
    toggleDisabled(uuid, deactivate, ai) {
      this.toggleLoading = true;

      const formData = serialize({
        deactivate: deactivate ? "true" : "false",
        ai: ai ? "true" : "false",
      });

      this.$inertia.patch(
        this.route("emission-factors.toggle", uuid),
        formData,
        {
          preserveScroll: true,
          preserveState: true,
          onFinish: () => {
            this.toggleLoading = false;
          },
        }
      );
    },
    bulkToggleDisabled(deactivate, ai) {
      const aiPrefix = ai ? "AI " : "";
      const action = deactivate ? "Disable" : "Enable";

      const assetCount = this.emissionFactors.totalCount ?? null;

      const formattedCount = assetCount ? `${assetCount} ` : "";

      const confirmText = `Are you sure you want to ${aiPrefix}${action} the currently filtered ${formattedCount}factors?`;

      if (!confirm(confirmText)) return;

      this.toggleLoading = true;

      const formData = serialize({
        deactivate: deactivate ? "true" : "false",
        ai: ai ? "true" : "false",
        filters: pickBy(this.filters),
      });

      this.$inertia.patch(
        this.route("emission-factors.bulk-toggle"),
        formData,
        {
          preserveScroll: true,
          preserveState: true,
          onFinish: () => {
            this.toggleLoading = false;
          },
        }
      );
    },
    handleSectorFilterChanged(value) {
      if (!value) return;

      this.filters.categoryId = null;
    },
  },
  watch: {
    filters: {
      handler: debounce(function () {
        this.pagination = {
          ...this.pagination,
          page: 1,
          itemsPerPage: 50,
        };
        this.changePage(this.pagination);
      }, 250),
      deep: true,
    },
  },
};
</script>
<style scoped>
.table-checkbox:deep(.v-input__slot) {
  justify-content: center;
}

.table-checkbox:deep(.v-input--selection-controls__input) {
  margin-right: 0px;
}

.factor-table:deep(.max-w-450) {
  max-width: 450px;
}

.factor-table:deep(.expand) {
  width: 99%;
}
</style>
