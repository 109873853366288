<template>
  <page-modal v-model="show" :title="modalTitle">
    <template #activator="{ on, attrs }">
      <v-btn
        v-bind="attrs"
        v-on="on"
        class="ml-4 mb-2"
        height="3rem"
        text
        color="primary"
        @click="$emit('clear')"
      >
        <div class="d-flex flex-column justify-center align-center">
          <v-icon class="black--text">mdi-plus</v-icon>
          <p class="teal--text text--darken-4 mb-0">CREATE</p>
        </div>
      </v-btn>
    </template>
    <template #body>
      <v-tabs v-model="tab">
        <v-tab
          v-for="contentTab in tabs"
          :key="contentTab.id"
          :value="contentTab.id"
        >
          {{ contentTab.name }}
        </v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab" class="fill-height fill-width px-4">
        <tab-general
          :tab-value="0"
          :name.sync="form.name"
          :type.sync="form.type"
          :tax-number.sync="form.taxNumber"
          :email.sync="form.email"
          :partner-types="partnerTypes"
          :form-errors="form.errors"
          :isSupport.sync="form.isSupport"
        />
        <tab-referral
          :tab-value="1"
          :discount.sync="form.discount"
          :discount-percentage.sync="form.discountPercentage"
          :kickback.sync="form.kickback"
          :kickback-percentage.sync="form.kickbackPercentage"
          :kickback-cutoff-in-months.sync="form.kickbackCutoffInMonths"
          :form-errors="form.errors"
        />
        <tab-support
          :tab-value="2"
          :address.sync="form.address"
          :contact-person.sync="form.contactPerson"
          :description.sync="form.description"
          :phone-number.sync="form.phoneNumber"
          :unique-selling-propositions.sync="form.uniqueSellingPropositions"
          :new-picture.sync="form.newPicture"
          :picture="form.picture"
          :form-errors="form.errors"
          :picture-allowed-mime-types="pictureAllowedMimeTypes"
        />
      </v-tabs-items>
    </template>
    <template v-if="referralUrl" #actions:start>
      <v-col cols="5" class="py-0">
        <v-text-field
          :value="referralUrl"
          label="Referral URL"
          hide-details
          readonly
          outlined
          dense
          :append-icon="
            referralUrlCopied ? 'mdi-check' : 'mdi-clipboard-outline'
          "
          @click="copyReferralUrl"
        >
        </v-text-field>
      </v-col>
    </template>
    <template #actions>
      <v-btn
        v-if="partner?.id"
        color="blue darken-1"
        text
        :loading="deleteLoading"
        :disabled="deleteLoading"
        @click="deletePartner"
      >
        <v-icon class="black--text">mdi-trash-can-outline</v-icon>
        <p class="teal--text text--darken-4 mb-0">DELETE</p>
      </v-btn>
      <v-btn color="blue darken-1" text @click="show = false">
        <v-icon class="black--text">mdi-close</v-icon>
        <p class="teal--text text--darken-4 mb-0">CLOSE</p>
      </v-btn>
      <v-btn
        color="blue darken-1"
        text
        :disabled="loading || deleteLoading"
        :loading="loading"
        @click="submit"
      >
        <v-icon class="black--text">mdi-content-save-outline</v-icon>
        <p class="teal--text text--darken-4 mb-0">
          {{ partner?.id ? "SAVE" : "CREATE" }}
        </p>
      </v-btn>
    </template>
  </page-modal>
</template>
<script>
import { serialize } from "object-to-formdata";

import PageModal from "@/Components/PageModal.vue";

import TabGeneral from "./FormTabs/TabGeneral.vue";
import TabReferral from "./FormTabs/TabReferral.vue";
import TabSupport from "./FormTabs/TabSupport.vue";

export default {
  components: { PageModal, TabGeneral, TabReferral, TabSupport },
  props: {
    partner: Object,
    value: Boolean,
    appUrl: String,
    partnerTypes: Array,
    pictureAllowedMimeTypes: Array,
  },
  data() {
    return {
      form: this.$inertia.form({
        name: null,
        email: null,
        taxNumber: null,
        type: null,
        discount: false,
        discountPercentage: 0,
        kickback: false,
        kickbackPercentage: 0,
        kickbackCutoffInMonths: null,
        contactPerson: null,
        address: null,
        phoneNumber: null,
        description: null,
        picture: null,
        newPicture: null,
        uniqueSellingPropositions: null,
        isSupport: null,
      }),
      active: false,
      loading: false,
      deleteLoading: false,
      referralUrlCopied: false,
      tab: null,
      tabs: [
        {
          id: 0,
          name: "GENERAL",
        },
        {
          id: 1,
          name: "REFERRAL",
        },
        {
          id: 2,
          name: "SUPPORT INFO",
        },
      ],
    };
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
    modalTitle() {
      if (this.partner?.id) return `EDIT PARTNER: ${this.partner.name}`;

      return "CREATE PARTNER";
    },
    referralUrl() {
      const referralCode = this.partner?.referralCode;

      if (!referralCode) return "";

      const url = new URL(this.appUrl);

      url.pathname = `referrals/${referralCode}`;

      return url.toString();
    },
  },
  methods: {
    setForm(partner) {
      if (!partner) {
        this.form.reset();
        return;
      }

      this.form.name = partner.name;
      this.form.email = partner.email;
      this.form.taxNumber = partner.taxNumber;
      this.form.type = partner.type;
      this.form.discount = partner.discount;
      this.form.discountPercentage = partner.discountPercentage;
      this.form.kickback = partner.kickback;
      this.form.kickbackPercentage = partner.kickbackPercentage;
      this.form.kickbackCutoffInMonths = partner.kickbackCutoffInMonths;
      this.form.contactPerson = partner.contactPerson;
      this.form.address = partner.address;
      this.form.phoneNumber = partner.phoneNumber;
      this.form.description = partner.description;
      this.form.picture = partner.picture;
      this.form.newPicture = null;
      this.form.uniqueSellingPropositions = partner.uniqueSellingPropositions;
      this.form.isSupport = partner.isSupport;
    },
    submit() {
      if (this.loading) return;

      this.loading = true;

      if (this.partner?.id) {
        this.form
          .transform((data) =>
            serialize({
              ...data,
              discount: data.discount ? "true" : "false",
              kickback: data.kickback ? "true" : "false",
            })
          )
          .put(this.route("partners.update", this.partner.id), {
            preserveScroll: true,
            onSuccess: () => {
              this.show = false;
              this.$emit("clear");
              this.form.reset();
            },
            onFinish: () => {
              this.loading = false;
            },
          });
      } else {
        this.form
          .transform((data) =>
            serialize({
              ...data,
              discount: data.discount ? "true" : "false",
              kickback: data.kickback ? "true" : "false",
            })
          )
          .post(this.route("partners.store"), {
            preserveScroll: true,
            onSuccess: () => {
              this.show = false;
              this.$emit("clear");
            },
            onFinish: () => {
              this.loading = false;
            },
          });
      }
    },
    deletePartner() {
      if (
        !this.partner.id ||
        !confirm(
          `Are you sure you want to delete the partner named '${this.partner.name}'?`
        )
      )
        return;

      this.deleteLoading = true;

      this.$inertia.delete(this.route("partners.destroy", this.partner.id), {
        preserveState: true,
        preserveScroll: true,
        onSuccess: () => {
          this.show = false;
          this.$emit("clear");
        },
        onFinish: () => {
          this.deleteLoading = false;
        },
      });
    },
    copyReferralUrl() {
      console.log("clicked");
      if (!this.referralUrl) return;

      window.navigator.clipboard.writeText(this.referralUrl);

      this.referralUrlCopied = true;

      setTimeout(() => {
        this.referralUrlCopied = false;
      }, 2000);
    },
    updateForm(key, value) {
      this.form[key] = value;
    },
  },
  watch: {
    partner: function (partner) {
      this.setForm(partner);
    },
  },
};
</script>
